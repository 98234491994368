import React from "react"
import Layout from "../components/layout"
import Row from "../components/row"
import text from "../data/nl"
import SEO from "../components/seo"
import { Link } from "gatsby"

const detailPage = () => (
  <Layout bigfoot={false}>
    <SEO title={text.pageNotFound.code} description={text.pageNotFound.subtitle}/>
    <Row size="small" classes=" pt-200 pb-60" id="OplossingenBusinessUnits">
      <div className="column col-12">
        <h2 className="t-50 uppercase bold">{text.pageNotFound.title}</h2>
        <p className="t-20 bold mb-20">{text.pageNotFound.subtitle}</p>
        <p className="mb-20">
          {text.pageNotFound.code}
          <br/>
          {text.pageNotFound.helpfulLinksTitle}
        </p>
        <ul>
          {text.pageNotFound.helpfulLinks.map((obj) =>
            <li key={obj.title}><Link to={obj.ref}>{obj.title}</Link></li>
          )}
        </ul>

      </div>
    </Row>
  </Layout>
)

export default detailPage;
